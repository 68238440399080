import React, { useEffect } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import franchiseServices from "../../services/franchiseServices";
import { toast } from "react-toastify";
import { ToastObjects } from "../../utils/toast/toastObject";
import { UPLOAD_URL } from "../../config";
import { formatHtmlContent } from "../../utils/helper";

function Service() {
  const dispatch = useDispatch();
  let services = useSelector((state) => state.franchise.services);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll:1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const getServices = async () => {
      try {
        await franchiseServices.getServices(dispatch);
      } catch (e) {
        toast.error(e.message, ToastObjects);
      }
    };
    getServices();
  }, [dispatch]);
  
  return (
    <section className="blog-items-slider mb-5">
      <div className="container">
        <div className="section-header">
          <h5 className="heading-design-h5 text-primary">
            Why Chwassa Jhol Momo Franchise?
          </h5>
        </div>
        <Slider {...settings}>
          {services &&
            services.length > 0 &&
            services.map((service, index) => (
              <div className="item" key={index}>
                <div className="franchise-service-card">
                   <div className="franchise-service-card-header">
                      <img
                        className="img-fluid"
                        src={UPLOAD_URL + service.image}
                        alt="franchise-thumbnail"
                      />
                    </div>
                    <div className="franchise-service-card-body mt-4">
                      <h5 className="franchise-title">{service.title}</h5>
                      <div className="franchise-service-description">
                          {formatHtmlContent(service.description)}
                        </div>
                    </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
);
}

export default Service;
