import {
  GET_SLIDERS,
} from "../constants/banquetConstants";

const initalState = {
  sliders: [],
};
export default function banquetReducers(state = initalState, action) {
  switch (action.type) {
    case GET_SLIDERS: {
      return {
        ...state,
        sliders: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
