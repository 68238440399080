import {
  GET_HOME_SLIDERS,
  GET_HOME_CATEGORIES,
  GET_HOME_BRANDS,
  GET_HOME_TOP_PRODUCTS,
  GET_HOME_SETTINGS,
  GET_HOME_BLOGS,
  GET_HOME_SERVICES,
  GET_HOME_OUTLETS,
  GET_HOME_BANNERS,
  GET_HOME_ABOUT_US,
  GET_PAGE_DETAIL,
  GET_PAGES
} from "../constants/homeConstants";

const initalState = {
  sliders: [],
  categories: [],
  brands: [],
  topProducts: [],
  settings: {},
  blogs: [],
  services: [],
  outlets: [],
  banners: [],
  about: [],
  page: [],
  pages: []
};
export default function homeReducers(state = initalState, action) {
  switch (action.type) {
    case GET_HOME_SLIDERS: {
      return {
        ...state,
        sliders: action.payload,
      };
    }
    case GET_HOME_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case GET_HOME_BRANDS: {
      return {
        ...state,
        brands: action.payload,
      };
    }
    case GET_PAGE_DETAIL: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case GET_PAGES: {
      return {
        ...state,
        pages: action.payload,
      };
    }
    case GET_HOME_TOP_PRODUCTS: {
      return {
        ...state,
        topProducts: action.payload,
      };
    }
    case GET_HOME_SERVICES: {
      return {
        ...state,
        services: action.payload,
      };
    }
    case GET_HOME_ABOUT_US: {
      return {
      ...state,
      about: action.payload,
    };
    }
    case GET_HOME_SETTINGS: {
      return {
        ...state,
        settings: action.payload,
      };
    }
    case GET_HOME_BLOGS: {
      return {
        ...state,
        blogs: action.payload,
      };
    }
    case GET_HOME_OUTLETS: {
      return {
        ...state,
        outlets: action.payload
      }
    }
    case GET_HOME_BANNERS: {
      return {
        ...state,
        banners: action.payload
      }
    }
    default: {
      return state;
    }
  }
}
