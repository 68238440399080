import {getBanquetSliders
} from "../store/actions/banquetActions";
import http from "./api";

const banquetService = {
  getSliders: async (dispatch) => {
    try {
      let res = await http.get("/api/v1/home/banquet-sliders");
      dispatch(getBanquetSliders(res.data));
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  sendBanquetInquiry: async (payload) => {
   try {
      let res = await http.post("/api/v1/home/banquet-inquiry", payload);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default banquetService;
