import React from "react";
import SingleProduct from "./views/product-details";
import BlogDetail from "./views/blog/blogDetail";
import Blogs, {loader as blogsLoader} from "./views/blog";
import Menu from "./views/product-menus";
import Checkout from "./views/checkout";
import ConfirmOrder from "./views/checkout/complete";
import Outlet from "./views/outlet";
import OutletDetail from "./views/outlet/outletDetail";
import Franchise from "./views/franchise";
import Banquet from "./views/banquet";
import Page from "./views/page";
import Profile from "./views/dashboard/profile";
import MainLayout from "./components/layout";
import DashboardLayout from "./components/sidebar";
import { authLoader } from "./loaders";
import Order from "./views/dashboard/order";
import Media from "./views/media/index";
import News from "./views/news";
import NewsDetail from "./views/news/newsDetail";
import { redirect } from "react-router-dom";
import { toast } from "react-toastify";

const HomePage = React.lazy(() => import("./views/home"));
const DashboardPage = React.lazy(() => import("./views/dashboard")); // Assuming you have a Dashboard component

export const routes = [
  {
    path: "/",
    name: "Home",
    element: <MainLayout />,
    children: [
      { index: true, name: "Home", element: <HomePage /> },
      { path: "product/:slug/:id", name: "SingleProduct", element: <SingleProduct /> },
      { path: "blog/:slug", name: "BlogDetail", element: <BlogDetail /> },
      { path: "blogs", name: "Blogs", element: <Blogs />, loader: blogsLoader},
      { path: "news", name: "News", element: <News />, loader: blogsLoader},
      { path: "news/:slug", name: "NewsDetail", element: <NewsDetail /> },
      { path: "menus", name: "Menu", element: <Menu /> },
      { path: "menu/:slug", name: "OutletMenu", element: <Menu />},
      { path: "medias", name: "Medias", element: <Media /> },
      { path: "checkout", name: "Checkout", element: <Checkout /> },
      { path: "outlets", name: "Outlet", element: <Outlet />  },
      { path: "outlet/:slug", name: "OutletDetail", element: <OutletDetail /> },
      { path: "order/success", name: "ConfirmOrder", element: <ConfirmOrder /> },
      { path: "franchise-enquiry", name: "Franchise", element: <Franchise /> },
      { path: "banquet-enquiry", name: "Banquet", element: <Banquet /> },
      { path: ":slug", name: "Page", element: <Page /> },
      {
        path: "dashboard",
        element: <DashboardLayout />,
        loader: async () => {
          const authenticatedUser = await authLoader().then((res) => {
            if (!res|| Object.keys(res).length === 0) {
              toast.error("Please login to access your dashboard");
              throw redirect('/');
              }
              return res;
          });
         return authenticatedUser;;
        } ,
        children: [
          { index: true, element: <DashboardPage /> },
          { path: "profile", element: <Profile /> },
          { path: "orders", element: <Order/>}
        ],
      },
    ],
  },
];
