import React, { useState, useEffect } from "react";
import "./index.css";
import {  Link } from "react-router-dom";
import http from "../../services/api";
import { UPLOAD_URL } from "../../config";
import { useSelector, useDispatch } from "react-redux";
import { priceFormat } from "../../utils/helper";
import { addToCart } from "../../store/actions/cartActions";
import Outlet from "../home/outlet";
import LoaderContainer from "../../containers/LoaderContainer";
import { hideLoader, showLoader } from "../../store/actions/globalActions";

function Menu() {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [category, setCategory] = useState();
  const [groupedProducts, setGroupedProducts] = useState({});
  const [menus, setMenus] = useState([]);
  const [catId, setCatId] = useState();
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const currency = useSelector((state) => state.home.settings).symbol;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProducts = async () => {
    try {
      dispatch(showLoader());
      const res = await http.get(`/api/v1/products`);
      if (res && res.status) {
        setProducts(res.data);
        setFilteredProducts(res.data);
      }
      dispatch(hideLoader());
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getProducts();
  }, [dispatch]);

  const renderNoProducts = () => {
    return (
      <div className="container text-center p-5">
        <h3 className="text-danger product-error">No products available in this category.</h3>
      </div>
  );
  };

  const handleMainCategoryClick = (categoryId) => {
    if (catId === categoryId && !selectedSubcategory) {
      return;
    }

    setSelectedSubcategory(null);
    setCatId(categoryId);
    filterProducts(categoryId, null);
  };

  const filterProducts = (categoryId, subcategoryId) => {
    const filtered = products.filter(
      (product) =>
        (!categoryId || product.categoryId === categoryId) &&
        (!subcategoryId || product.subCategoryId === subcategoryId)
    );

    const grouped = {};
    filtered.forEach((product) => {
      const subCategoryId = product.subCategoryId;
      if (!grouped[subCategoryId]) {
        grouped[subCategoryId] = {
          subCategory: product.subCategory,
          products: [product],
        };
      } else {
        grouped[subCategoryId].products.push(product);
      }
    });
    setGroupedProducts(grouped);
  };

  useEffect(() => {
    const getProductsByCat = async () => {
      try {
        const res = await http.get(`/api/v1/menus`);
        if (res && res.status) {
          setMenus(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getProducts();

    const getCategory = async () => {
      try {
        const res = await http.get(`/api/v1/home/category/${catId}`);
        if (res && res.status) {
          setCategory(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getProductsByCat();
    getCategory();
  }, [catId]);

  const addToCartHandler = (id) => {
    dispatch(addToCart(id, 1));
  };

  const renderBreadcrumb = () => {
    if (selectedSubcategory) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Link to="/" className="breadcrumb-container">
                <strong>
                  <span className="mdi mdi-home" /> Home
                </strong>
              </Link>{" "}
              <span className="custom-chevron"/>{" "}
              <Link to="#" className="breadcrumb-container">Menu</Link>{""}
              <span className="custom-chevron"/>{" "}
              <Link to="#" className="breadcrumb-container">{category && category.name}</Link>{" "}
               <span className="custom-chevron"/>{" "}
              <Link to="#" className="breadcrumb-container">{selectedSubcategory.name}</Link>{" "}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Link to="/" className="breadcrumb-container">
                <strong>
                  <span className="mdi mdi-home" /> Home
                </strong>
              </Link>{" "}
              <span className="custom-chevron"/>{" "}
              <Link to="#" className="breadcrumb-container">Menu</Link>{""}
              <span className="custom-chevron"/>{" "}
              <Link to="#" className="breadcrumb-container">{category && category.name}</Link>{" "}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
      <LoaderContainer>
      <section className="">
        <div className="section-header-blog">
          <h5 className="p-2">
            {renderBreadcrumb()}
          </h5>
        </div>
        <div className="card all-menu-grid mb-5">
          <div className="card-body">
            <div className="container">
              <div className="row product-page-bg">
                <div className="col-md-3">
                  <h2 className="text-center menu-title">
                    <i className="fa fa-angle-double-right"></i>Menu
                  </h2>
                  <ul className="list-group menus-list clearfix" role="tablist">
                    {menus &&
                      menus.length > 0 &&
                      menus.map((menu) => (
                        <li className="" key={menu.id}>
                            <h5 className="m-1">
                              <button
                                className={`${catId === menu.id ? "active" : ""} list-group-menu menus-list-hover w-100`}
                                type="button"
                                onClick={() => handleMainCategoryClick(menu.id)}
                              >
                                {menu.name.toUpperCase()}
                              </button>
                            </h5>
                          </li>
                      ))}
                  </ul>
                </div>
                <div className="product-top-dt col-md-9 container">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="text-center mt-4 menu-title text-primary">
                        <i className="fa fa-angle-double-right"></i>All Menus
                      </p>
                      <div className="menu-list-container">
                        {catId || selectedSubcategory
                          ? Object.values(groupedProducts).map((group) => (
                              <div key={group.subCategory.id} className="subcategory-group">
                                <h3 className="sub-menu-header">{group.subCategory.name}</h3>
                                <ul className="menu-list m-3">
                                  {group.products.map((product, index) => (
                                    <li className="menu-item full-menu" key={index}>
                                        <div className="menu-item-img">
                                          <img
                                            className="img-fluid"
                                            src={UPLOAD_URL + product.thumbnailImage}
                                            alt="product"
                                          />
                                        </div>
                                        <div className="menu-item-details">
                                        <h5>{product.name}</h5>
                                          <p>{product.summary}</p>
                                          <p>
                                            <strong>
                                              <span className="mdi mdi-approval" /> Available in
                                            </strong>{" "}
                                            - {product.quantity}
                                          </p>
                                          <p className="offer-price">
                                            {priceFormat(currency, product.priceAfterDiscount)}
                                            <i className="mdi mdi-tag-outline" />
                                            <br />
                                            <span className="regular-price">
                                              {priceFormat(currency, product.price)}
                                            </span>
                                          </p>
                                          <button
                                            type="button"
                                            onClick={() => addToCartHandler(product.id)}
                                            className="btn menu-cart-button btn-sm float-right p-1"
                                          >
                                            <i className="mdi mdi-cart-outline" /> Add To Cart
                                          </button>
                                        </div> 
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))
                          : 
                            ( filteredProducts.length > 0 ? (
                              <ul className="menu-list">
                                <div className="row m-3">
                                      {filteredProducts.map((product, index) => (
                                        <li className="menu-item col-md-12" key={index}>
                                            <div className="menu-item-img">
                                              <img
                                                className="img-fluid"
                                                src={UPLOAD_URL + product.thumbnailImage}
                                                alt="product"
                                              />
                                            </div>
                                          <div className="menu-item-details">
                                              <h5>{product.name}</h5>
                                                  <p>{product.summary}</p>
                                            <p>
                                              <strong>
                                                <span className="mdi mdi-approval" /> Available in
                                              </strong>{" "}
                                              - {product.quantity}
                                            </p>
                                            <p className="offer-price">
                                              {priceFormat(currency, product.priceAfterDiscount)}
                                              <i className="mdi mdi-tag-outline" />
                                              <br />
                                              <span className="regular-price">
                                                {priceFormat(currency, product.price)}
                                              </span>
                                            </p>
                                            <button
                                              type="button"
                                              onClick={() => addToCartHandler(product.id)}
                                              className="btn menu-cart-button btn-sm float-right p-1"
                                            >
                                              <i className="mdi mdi-cart-outline" /> Add To Cart
                                            </button>
                                          </div>
                                        </li>
                                      ))}
                                    </div>
                                    </ul>
                                  ) : (
                            renderNoProducts()
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <Outlet/>              
        </div>
        </section>
      </LoaderContainer>
  );
}

export default Menu;
