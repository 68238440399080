import { GET_TESTONOMIALS, GET_SERVICES, GET_BANNERS, GET_MEDIA} from "../constants/franchiseConstants";

const initalState = {
  testonomials: [],
  services: [],
  banners: [],
  media: {}
};
export default function homeReducers(state = initalState, action) {
  switch (action.type) {
    case GET_TESTONOMIALS: {
      return {
        ...state,
        testonomials: action.payload,
      };
    }
    case GET_SERVICES: {
      return {
        ...state,
        services: action.payload
      }
    }
    case GET_BANNERS: {
      return {
        ...state,
        banners: action.payload
      }
    }
    case GET_MEDIA: {
      return {
        ...state,
        media: action.payload
      }
    }
    default: {
      return state;
    }
  }
}
