import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useOutletContext } from "react-router-dom";

const Profile = () => {
  const authUser = useOutletContext();
  const initialValues = {
    name: authUser.name,
    email: authUser.email,
    password: "",
    confirmPassword: "",
  };

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().when("confirmPassword", {
    is: (confirmPassword) => !!confirmPassword,
    then: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return value === this.parent.confirmPassword;
      }
    ),
  }),
  confirmPassword: Yup.string(),
});


  const handleSubmit = (values, { setSubmitting }) => {
    setTimeout(() => {
      console.log("Form submitted:", values);
      setSubmitting(false);
    }, 400);
  };

  return (
    <div className="container mb-5">
      <h2 className="text-primary">Update Your Details</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Field
                  type="text"
                  id="Name"
                  name="name"
                  placeholder="Full Name"
                  className="form-control"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-md-6 mb-3">
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-md-12">
                <hr />
              </div>
              <div className="col-md-6 mb-3">
                <Field
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-md-6 mb-3">
                <Field
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className="form-control"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-sm btn-danger"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Update Profile"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Profile;
