import {
  getBlogDetail,
} from "../store/actions/blogActions";
import http from "./api";

const blogService = {
  getBlogDetail: async (slug, dispatch) => {
    try {
      let res = await http.get(`/api/v1/blog/${slug}`);
      dispatch(getBlogDetail(res.data));
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  },
}
export default blogService;
