import { Link } from "@mui/material";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { UPLOAD_URL } from "../../config";
import { ToastObjects } from "../../utils/toast/toastObject";
import { useDispatch, useSelector } from "react-redux";
import homeServices from "../../services/homeServices";
import { NavLink } from "react-router-dom";
import { hideLoader, showLoader } from "../../store/actions/globalActions";

function Footer() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.home.settings);
  let categories = useSelector((state) => state.home.categories);
  if (categories.length > 0) {
    categories = categories.slice(0, 6);
  }
  useEffect(() => {
    const getSettings = async () => {
      try {
        dispatch(showLoader())
        await homeServices.getHomeSettings(dispatch);
        dispatch(hideLoader())
      } catch (error) {
        toast.error(error.message, ToastObjects);
      }
    };
    getSettings();
  }, [dispatch]);

  return (
    <div>
      <section className="section-padding footer border-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="mb-3">
                <Link className="logo" to="/">
                  <img
                    className="footer-logo"
                    width={"100px"}
                    height={"100px"}
                    src={UPLOAD_URL + settings.logo}
                    alt={settings.site_name}
                  />
                </Link>
              </h4>
              <h4 className="text-primary">About Our Company</h4>
              <p className="text-justify text-dark">
                {settings.site_short_description}
              </p>
            </div>
            <div className="col-lg-3">
              <h4 className="mb-3 text-primary">Get In Touch</h4>
              <p className="mb-2">
                <Link to="#" className="text-dark">
                  <i className="mdi mdi-phone" /> {settings.phone}
                </Link>
              </p>
              <p className="mb-2">
                <Link to="#" className="text-dark">
                  <i className="mdi mdi-gmail" /> {settings.email}
                </Link>
              </p>
              <p className="mb-2">
                <Link to="#" className="text-dark">
                  <i className="mdi mdi-map-marker" /> {settings.address}
                </Link>
              </p>
            </div>
            <div className="col-lg-3">
              <h4 className="mb-3 text-primary">Quick Links</h4>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <NavLink to="/" className="text-dark">Home</NavLink>
                </li>
                 <li className="mb-2">
                  <NavLink to={'/medias'} className="text-dark">Medias</NavLink>
                </li>
                <li className="mb-2">
                  <Link to="#" className="text-dark">Terms & Privacy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-4 pb-4 footer-bottom">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6 col-sm-6">
              <p className="mt-1 mb-0">
                © Copyright {new Date().getFullYear()}
                <strong className="text-dark"></strong>
                . All Rights Reserved
                <br />
                <small className="mt-0 mb-0">
                  Made with <i className="mdi mdi-heart text-danger" />
                  by{" "}
                  <a href="#" target="_blank">
                    k & k Tech
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
