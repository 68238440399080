import React, { useState } from "react";
import homeServices from "../../services/homeServices";
import { UPLOAD_URL } from "../../config";
import { Link, useLoaderData } from "react-router-dom";
import { formatDateTime } from "../../utils/helper";
import Pagination from "../../components/pagination";
import { useSelector } from "react-redux";

export async function loader() {
  try {
    const { data } = await homeServices.getHomeBlogs();
    return data;
  } catch (error) {
    return [];
  }
}

function News() {
  const allBlogs = useLoaderData();
  const news = allBlogs.filter((news) => news.type === 'NEWS')
  const appName = useSelector((state) => state.home.settings).site_name;
  const [currentPage, setCurrentPage] = useState(1);
  const [newsPerPage] = useState(6);
  const indexOfLastOutlet = currentPage * newsPerPage;
  const indexOfFirstOutlet = indexOfLastOutlet - newsPerPage;
  const currentNews = news.slice(indexOfFirstOutlet, indexOfLastOutlet);

  const onPageChange = (pageNumber) => setCurrentPage(pageNumber);
 
  const renderBreadCrumb = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
               <Link to="/" className="breadcrumb-container">
                <strong>
                  <span className="mdi mdi-home" /> Home
                </strong>
              </Link>{" "}
              <span className="custom-chevron"/>{" "}
              <Link to="#" className="breadcrumb-container">News</Link>
            </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <section className="blog-items-slider">
        <div className="section-header-blog">
            <h5 className="p-2" >
              {renderBreadCrumb()}
            </h5>
        </div>
        <div className="container mt-5" id="header-category-bk">
          <div className="section-header">
            <h5 className="heading-design-h5 mt-4 mb-4 text-primary">
            All About {appName}
            </h5>
          </div>
          <div className="row">
            {currentNews &&
              currentNews.length > 0 &&
              currentNews.map((news, index) => (
                <div className="col-12 col-md-6 col-lg-4 mb-4" key={index}>
                  <div className="item" key={index}>
                <div className="blog-card">
                  <Link to={{ pathname:`/news/${news.slug}`, state: news }}>
                    <div className="blog-card-header">
                      <img
                        className="img-fluid"
                        src={UPLOAD_URL + news.image}
                        alt="blog-thumbnail"
                      />
                    </div>
                    <div className="blog-card-body">
                      <h5 className="blog-title">{news.name}</h5>
                      <div className="blog-description">
                          {`${news.summary?.substring(0, 400)}...`}
                        </div>
                    </div>
                  </Link>
                  <div className="blog-card-footer">
                    <Link
                      to={{ pathname:`/news/${news.slug}`, state: news }}
                      className="btn read-more-btn btn-sm float-right"
                    >
                      <i className="mdi mdi-arrow-right" /> Read More
                    </Link>
                  </div>
                </div>
              </div>
                </div>
              ))}
          </div>
          <Pagination currentPage={currentPage} totalPages={Math.ceil(news.length / newsPerPage)} onPageChange={onPageChange} />
        </div>
      </section>
    </div>
  );
}

export default News;
