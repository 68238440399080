import React from 'react';
import { useOutletContext } from 'react-router-dom';

const Order = () => {
 const { orders } = useOutletContext();
  return (
    <div className="container mt-3">
      <span className='text-primary h3'>My Orders</span>
      <table className="table table-striped mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Order ID</th>
            <th>Product</th>
            <th>Quantity</th>
            <th>Total Price</th>
            <th>Payment Status</th>
            <th>Delivery Status</th>
          </tr>
        </thead>
        <tbody>
          {orders?.map((order, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{order.orderId}</td>
              <td>{order.productName}</td>
              <td>{order.quantity}</td>
              <td>${order.totalPrice.toFixed(2)}</td>
              <td className='m-2 badge badge-danger'>{order.paymentStatus}</td>
              <td>{order.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Order;
