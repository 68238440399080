import {
  getFranchiseServices,
  getFranchiseTestonomials,
  getFranchiseBanners,
  getFranchiseMedia
} from "../store/actions/franchiseActions";
import http from "./api";

const franchiseServices = {
 
  getTestonomials: async (dispatch) => {
    try {
      let res = await http.get("/api/v1/franchise/testonomials");
      dispatch(getFranchiseTestonomials(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

   getServices: async (dispatch) => {
    try {
      let res = await http.get("/api/v1/franchise/services");
      dispatch(getFranchiseServices(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getBanners: async(dispatch) => {
     try {
      let res = await http.get("/api/v1/franchise/banners");
      dispatch(getFranchiseBanners(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getMedia: async(dispatch) => {
    try {
      let res = await http.get("/api/v1/franchise/media");
      dispatch(getFranchiseMedia(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  sendFranchiseInquiry: async (payload) => {
   try {
      let res = await http.post("/api/v1/home/franchise-inquiry", payload);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
}

export default franchiseServices