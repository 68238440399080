import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

function DeliveryDetails({ onSelectDeliveryAddress }) {
  const customer = useSelector((state) => state.user.profile);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full name is required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .required("Phone number is required"),
    country: Yup.string().required("Country is required"),
    postalCode: Yup.string().required("Postal code is required"),
    address: Yup.string().required("Shipping address is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: customer.name || "",
      phone: "",
      address: "",
      country: "",
      postalCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSelectDeliveryAddress(values);
      setIsCollapsed(true); // Collapse after submission
    },
  });

  const handleNextClick = () => {
    formik.validateForm().then(() => {
      if (Object.keys(formik.errors).length === 0) {
        setIsCollapsed(true); // Collapse if form is valid
      }
    });
  };

  return (
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label className="control-label">
                Full Name <span className="required">*</span>
              </label>
              <input
                className="form-control border-form-control"
                type="text"
                name="name"
                placeholder="Full Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-danger">{formik.errors.name}</div>
              ) : null}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="control-label">
                Phone <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control border-form-control"
                name="phone"
                value={formik.values.phone}
                placeholder="Enter phone number."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="text-danger">{formik.errors.phone}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label className="control-label">
                Country <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control border-form-control"
                name="country"
                value={formik.values.country}
                placeholder="Enter country name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.country && formik.errors.country ? (
                <div className="text-danger">{formik.errors.country}</div>
              ) : null}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="control-label">
                Postal Code <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control border-form-control"
                name="postalCode"
                placeholder="Enter postal code"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.postalCode && formik.errors.postalCode ? (
                <div className="text-danger">{formik.errors.postalCode}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="control-label">
                Shipping Address <span className="required">*</span>
              </label>
              <textarea
                className="form-control border-form-control"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter shipping address."
              />
              {formik.touched.address && formik.errors.address ? (
                <div className="text-danger">{formik.errors.address}</div>
              ) : null}
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-sm btn-warning mb-2 next-btn14"
          onClick={handleNextClick}
          disabled={!formik.isValid}
          data-toggle="collapse"
          data-target="#collapseThree"
          aria-expanded={!isCollapsed}
        >
          NEXT
        </button>
      </form>
    </div>
  );
}

export default DeliveryDetails;
