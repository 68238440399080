import React, { useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import franchiseServices from "../../services/franchiseServices";
import { toast } from "react-toastify";
import { ToastObjects } from "../../utils/toast/toastObject";
import { UPLOAD_URL } from "../../config";
import { formatHtmlContent } from "../../utils/helper";

function Testimonial() {
  const dispatch = useDispatch();
  let testonomials = useSelector((state) => state.franchise.testonomials);

  useEffect(() => {
    const getTestonomials = async () => {
      try {
        await franchiseServices.getTestonomials(dispatch);
      } catch (e) {
        toast.error(e.message, ToastObjects);
      }
    };
    getTestonomials();
  }, [dispatch]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
      <section className="testonomial-items-slider mb-5">
      <div className="container">
        <div className="section-header">
          <h5 className="heading-design-h5 pt-5 testonomial-section-heading">
            What our Franchise owners have to say?
          </h5>
        </div>
        <Slider {...settings}>
          {testonomials &&
            testonomials.length > 0 &&
            testonomials.map((testonomial, index) => (
              <div className="item" key={index}>
                <div className="testonomial-card">
                 <Link to={{ pathname: `/testonomial/${testonomial.id}`, state: testonomial }} style={{ textDecoration: "none" }}>
                    <div className="testonomial-card-header">
                      <img
                        className="img-fluid"
                        src={UPLOAD_URL + testonomial.image}
                        alt="testonomial-thumbnail"
                      />
                    </div>
                    <div className="testonomial-card-body">
                      <span style={{ color: "orange", fontSize: "24px", fontWeight: "bold" }}>{testonomial.title}</span>
                      <div style={{ color: "black", fontSize: "18px" }} className="testonomial-content pt-4">
                        {formatHtmlContent(testonomial.description)}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonial;
