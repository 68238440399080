import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../components/loader';

const LoaderContainer = ({ children }) => {
  const isLoading = useSelector((state) => state.global.isLoading);
  return isLoading ? <Loader /> : children;
};

export default LoaderContainer;
