import React from 'react';
import loaderImage from "../../loader.gif"

const Loader = () => (
  <div className="loader-container">
    <img src={loaderImage} alt="Loading" />
  </div>
);

export default Loader;
