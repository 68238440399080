import {
  GET_BLOG_DETAIL
} from "../constants/blogConstants";

const initalState = {
  blog: {}
};
export default function blogReducers(state = initalState, action) {
  switch (action.type) {
    case GET_BLOG_DETAIL: {
      return {
        ...state,
        blog: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
