import React, { useEffect, useState } from "react";
import { Typography, Grid, Paper } from "@mui/material";
import FranchiseForm from "./franchiseForm";
import Service from "./service";
import Testonomial from "./testonomial";
import { useDispatch, useSelector } from "react-redux";
import franchiseServices from "../../services/franchiseServices";
import { toast } from "react-toastify";
import { ToastObjects } from "../../utils/toast/toastObject";
import { formatHtmlContent } from "../../utils/helper";
import { UPLOAD_URL } from "../../config";
import homeServices from "../../services/homeServices";
import { hideLoader, showLoader } from "../../store/actions/globalActions";
import LoaderContainer from "../../containers/LoaderContainer";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function Franchise() {
    const dispatch = useDispatch();
    let banners = useSelector((state) => state.franchise.banners);
    const slug = 'about-us';
    const about = useSelector((state) => state.home.page);
    const media = useSelector((state) => state.franchise.media)
    let banner = {};
    let topBanner = {};
    if (banners.length > 0) {
        banner = banners.filter(banner => banner.type === "bottom")[0];
        topBanner = banners.filter(banner => banner.type === "service")[0];
    }

    const [formData, setFormData] = useState({
        full_name: "",
        location_name: "",
        country_code: "",
        phone_number: "",
        email_address: "",
    });

    const handleSubmit = async (values) => {
        try {
            await franchiseServices.sendFranchiseInquiry(values);
            setFormData({
                full_name: "",
                location_name: "",
                country_code: "",
                phone_number: "",
                email_address: "",
            });
            toast.success("Inquiry submitted successfully!", ToastObjects);
        } catch (error) {
            toast.error(error.message, ToastObjects);
        }
    };

    useEffect(() => {
        const getBanners = async () => {
            dispatch(showLoader());
            try {
                await franchiseServices.getBanners(dispatch);
                dispatch(hideLoader());
            } catch (e) {
                toast.error(e.message, ToastObjects);
            }
        };
        const getAboutUs = async () => {
            try {
                await homeServices.getPageDetail(slug, dispatch);
            } catch (e) {
                toast.error(e.message, ToastObjects);
            }
        };
        const getMedia = async () => {
            try {
                await franchiseServices.getMedia(dispatch);
            } catch (e) {
                toast.error(e.message, ToastObjects);
            }
        };
        getBanners();
        getAboutUs();
        getMedia();
    }, [dispatch]);

    const renderBreadcrumb = () => {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Link to="/" className="breadcrumb-container">
                            <strong>
                                <span className="mdi mdi-home" /> Home
                            </strong>
                        </Link>{" "}
                        <span className="custom-chevron"/>{" "}
                        <Link to="#" className="breadcrumb-container">Franchise</Link>{" "}
                    </div>
                </div>
            </div>
        );
    }

    const FranchiseSchema = Yup.object().shape({
        full_name: Yup.string().required("Name is required"),
        location_name: Yup.string().required("Location is required"),
        country_code: Yup.string().required("Country Code is required"),
        phone_number: Yup.string().required("Phone Number is required"),
        email_address: Yup.string().email("Invalid email").required("Email is required"),
    });

    return (
        <>
            <LoaderContainer>
                <div className="section-header-blog">
                    <h5 className="p-2" >
                        {renderBreadcrumb()}
                    </h5>
                </div>
                <section className="pb-5 franchise-section">
                    <div className="container">
                        <div className="franchise-top-section">
                            <Grid container spacing={6}>
                                <Grid item xs={12} md={6}>
                                    <img
                                        src={UPLOAD_URL + topBanner?.image}
                                        alt="about-thumbnail"
                                        style={{ width: "100%", height: "auto" }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} className="franchise-form">
                                    <Paper
                                        style={{
                                            backgroundColor: "#FFF",
                                            padding: "20px 20px 30px",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <div className="form-holder">
                                            <Formik
                                                initialValues={{
                                                    full_name: "",
                                                    location_name: "",
                                                    country_code: "",
                                                    phone_number: "",
                                                    email_address: "",
                                                }}
                                                validationSchema={FranchiseSchema}
                                                onSubmit={handleSubmit}
                                            >
                                                {({ isSubmitting }) => (
                                                    <Form className="row">
                                                        <div className="col-md-12 col-lg-12 top_form">
                                                            <p className="text-primary" style={{ fontSize: "24px", paddingTop: "20px" }}>
                                                                Fill your Franchise enquiry.
                                                            </p>
                                                            <Field type="text" name="full_name" className="form-control mb-4" placeholder="Name" required="" />
                                                            <ErrorMessage name="full_name" component="div" className="text-danger" />
                                                            <Field type="text" name="phone_number" className="form-control mb-4" placeholder="Phone" required="" />
                                                            <ErrorMessage name="phone_number" component="div" className="text-danger" />
                                                            <Field type="email" name="email_address" className="form-control mb-4" placeholder="Email" required="" />
                                                            <ErrorMessage name="email_address" component="div" className="text-danger" />
                                                            <Field type="text" name="location_name" className="form-control mb-4" placeholder="Location" required="" />
                                                            <ErrorMessage name="location_name" component="div" className="text-danger" />
                                                            <Field type="text" name="country_code" className="form-control mb-4" placeholder="Country Code" required="" />
                                                            <ErrorMessage name="country_code" component="div" className="text-danger" />
                                                            <div class="col-md-12 mt-5 text-right"><button type="submit"     disabled={isSubmitting} class="btn btn-secondary"><span class="text-secondary">Submit</span></button></div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </section>
                <div className="container mb-5">
                    <Grid container spacing={6} className="mt-5">
                        {media && (
                            <Grid item xs={12} md={6}>
                                {media.link ? (
                                    <iframe
                                        width="100%"
                                        height="100%" // Set height to 100%
                                        src={media.link}
                                        title={media.name}
                                        allowFullScreen
                                    />
                                ) : media.file ? (
                                    <video
                                        controls
                                        src={UPLOAD_URL + media.file}
                                        style={{ width: '100%', height: '100%' }} // Set width and height to 100%
                                    />
                                ) : (
                                    ''
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12} md={media ? 6 : 12}>
                            <div style={{ height: '100%', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div>
                                    <Typography className="text-primary mb-4" variant="h4" gutterBottom>
                                        About Us
                                    </Typography>
                                    <p className="franchise-about">
                                        {formatHtmlContent(about?.excerpt)}
                                    </p>
                                </div>
                                {/* This empty div will help in aligning the content properly */}
                                <div></div>
                            </div>
                        </Grid>
                    </Grid>

                </div>
                <FranchiseForm />
                <Service />
                <Testonomial/>
                <div className="banner-4 franchise-bottom-banner"
                     style={{
                         background: `url(${UPLOAD_URL + banner?.image}) center/cover no-repeat`,
                     }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                                <div className="text-center">
                                    <h2 className="franchise-banner-txt ">{banner.name}</h2>
                                    <h4 className="h4-xl franchise-banner-sub-txt ">{formatHtmlContent(banner.description)}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoaderContainer>
        </>
    );
}

export default Franchise;
