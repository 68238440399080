  import React from "react";
  import { NavLink, Outlet, useLoaderData } from "react-router-dom";

  const DashboardLayout = () => {
    const dashboardData = useLoaderData();
    console.log(2323, useLoaderData())
    return (
      <section>
        <div className="section-header-blog">
          <h5 className="p-2">{/* Render Breadcrumb component */}</h5>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <nav
                id="sidebarMenu"
                className="collapse d-lg-block sidebar collapse bg-white"
              >
                <div className="">
                  <div className="list-group list-group-flush mx-3 mt-4">
                    <NavLink
                      to="/dashboard"
                      className="list-group-item list-group-item-action py-2 ripple"
                    >
                      <i className="fas fa-tachometer-alt fa-fw me-3"></i>
                      <span>Main dashboard</span>
                    </NavLink>
                    <NavLink
                      to="/dashboard/profile"
                      className="list-group-item list-group-item-action py-2 ripple"
                    >
                      <i className="fas fa-chart-area fa-fw me-3"></i>
                      <span>Profile</span>
                    </NavLink>
                    <NavLink
                      to="/dashboard/orders"
                      className="list-group-item list-group-item-action py-2 ripple"
                    >
                      <i className="fas fa-lock fa-fw me-3"></i>
                      <span>My Orders</span>
                    </NavLink>
                  </div>
                </div>
              </nav>
            </div>
            <div className="col-md-8">
              <main>
                <Outlet context={dashboardData} />
              </main>
            </div>
          </div>
        </div>
      </section>
    );
  };

  export default DashboardLayout;
