import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import CartSideBar from "../../views/cart-sidebar";
import Login from "../../views/auth/login";
import { toast } from "react-toastify";
import { ToastObjects } from "../../utils/toast/toastObject";
import { useDispatch, useSelector } from "react-redux";
import { UPLOAD_URL } from "../../config";
import homeServices from "../../services/homeServices";
import fbImage from '../../fb.png';
import instaImage from '../../insta.png';
import linkedinImage from '../../linkedin.png';
import twitterImage from '../../twitter.png';

const Navigation = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const settings = useSelector((state) => state.home.settings);
  const pages = useSelector((state) => state.home.pages);
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const getPages = async () => {
      try {
        await homeServices.getPages(dispatch);
      } catch (e) {
        toast.error(e.message, ToastObjects);
      }
    };
    getPages();
  }, [dispatch]);

  const checkAuth = () => {
    const accessToken = localStorage.getItem("accessToken");
    return !!accessToken;
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const isScrolled = window.scrollY > 0;
  //     setIsSticky(isScrolled);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleLogout = async () => {
    try {
      localStorage.removeItem("accessToken");
      window.location.reload();
    } catch (e) {
      toast.error("Something went wrong.", ToastObjects);
    }
  };
    
  const isActiveLink = (path) => {
    return location.pathname.startsWith(path);
  };


  return (
    <div>
      <header className={`header clearfix ${isSticky ? "sticky" : ""}`}>
        <nav className="navbar navbar-light navbar-expand-lg osahan-menu">
          <div className="container navbar-bg d-flex justify-content-between">
            <NavLink className="navbar-brand" to="/" style={{ color: "#fff" }}>
              <img src={UPLOAD_URL + settings.logo} alt="logo" />
            </NavLink>
            <button
              className="navbar-toggler navbar-toggler-white"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav main_nav ml-auto">
                <li className="nav-item dropdown">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    About Us
                  </NavLink>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    {pages.length &&
                      pages.map((page, index) => (
                        <NavLink
                          key={index}
                          className="dropdown-item"
                          to={page.slug}
                        >
                          {page.name}
                        </NavLink>
                      ))}
                  </div>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${
                     ( isActiveLink("/outlets") ||  isActiveLink("/outlet")) ? "active" : ""
                    }`}
                    to="/outlets"
                  >
                    Outlets
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${
                      isActiveLink("/menus") ? "active" : ""
                    }`}
                    to="/menus"
                  >
                    Order
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${
                      isActiveLink("/franchise-enquiry") ? "active" : ""
                    }`}
                    to="/franchise-enquiry"
                  >
                    Franchise Enquiry
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${
                      isActiveLink("/banquet-enquiry") ? "active" : ""
                    }`}
                    to="/banquet-enquiry"
                  >
                    Banquet Enquiry
                  </NavLink>
                </li>
                {/* Mega Menu */}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                 <span className="ico-holder">
                  <span className="glyphicon glyphicon-option-vertical"></span>
                  <span className="fa fa-ellipsis-v"></span>
                  <span className="fa fa-ellipsis-v"></span>
                </span>
                  </a>
                  <div
                    className="dropdown-menu mega-menu-container full-width-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <div className="mega-menu-box">
                          <div className="mega-menu-media">
                            <div className="mega-menu-media-info">
                              <h4 className="mega-menu-heading mb-30">
                                <Link to={'/'}>Home</Link>
                              </h4>
                              <h4 className="mega-menu-heading mb-30">
                                <Link to={'/about-us'}>About US</Link>
                              </h4>
                              <h4 className="mega-menu-heading">
                                 <Link to={'/blogs'}>Blogs</Link>
                              </h4>
                              <h4 className="mega-menu-heading">
                                  <Link to={'/outlets'}>Outlet Information</Link>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="mega-menu-box">
                          <div className="mega-menu-media">
                            <div className="mega-menu-media-info">
                              <h4 className="mega-menu-heading">
                                <Link to="#">Contact Us</Link>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mega-menu-box last-menu-box">
                          <div className="mega-menu-media">
                            <div className="mega-menu-media-info">
                              <h4 className="mega-menu-heading">
                                <Link to={'/news'}>News</Link>
                              </h4>
                              <h4 className="mega-menu-heading">
                                <Link to="/medias">Videos</Link>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                         <hr/>
                        <h5 style={{ textAlign: "center", margin: "0px !important"}}>Social Media</h5>
                        <ul className="bottom-footer-list text-center clearfix">
                          <li>
                            <a href={settings.facebookUrl} target="_blank" className="text-decoration-none social-links" rel="noreferrer">
                              <img src={fbImage} alt="Facebook" style={{ width: "30px", height: "30px" }} />
                            </a>
                          </li>
                          <li>
                            <a href={settings.instagramUrl} target="_blank" className="text-decoration-none social-links" rel="noreferrer">
                              <img src={instaImage} alt="Instagram" style={{ width: "30px", height: "30px" }} />
                            </a>
                          </li>
                          <li>
                            <a href={settings.linkedinUrl} target="_blank" className="text-decoration-none social-links" rel="noreferrer">
                              <img src={linkedinImage} alt="Linkedin" style={{ width: "30px", height: "30px" }} />
                            </a>
                          </li>
                          <li>
                            <a href={settings.twitterUrl} target="_blank" className="text-decoration-none social-links" rel="noreferrer">
                              <img src={twitterImage} alt="Twitter" style={{ width: "30px", height: "30px" }} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                {/* End Mega Menu */}
              </ul>

              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  {checkAuth() ? (
                    <div className="nav-item dropdown">
                      <NavLink
                        className="nav-link dropdown-toggle cart-btn"
                        to="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="mdi mdi-account-circle px-2" />
                        {profile.name}
                      </NavLink>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <NavLink className="dropdown-item" to="/dashboard">
                          <i className="uil uil-apps" />
                          Dashboard
                        </NavLink>
                        <NavLink
                          className="dropdown-item"
                          to="/dashboard/profile"
                        >
                          <i
                            className="mdi mdi-account-outline"
                            aria-hidden="true"
                          ></i>
                          My Profile
                        </NavLink>
                        <NavLink
                          className="dropdown-item"
                          to="/dashboard/orders"
                        >
                          <i
                            className="mdi mdi-format-list-bulleted"
                            aria-hidden="true"
                          ></i>{" "}
                          Orders List
                        </NavLink>
                        <div className="dropdown-divider"></div>
                        <button
                          to="#"
                          className="dropdown-item"
                          onClick={handleLogout}
                        >
                          <i className="mdi mdi-lock" aria-hidden="true"></i>{" "}
                          Logout
                        </button>
                      </div>
                    </div>
                  ) : (
                    <NavLink
                      to="#"
                      data-target="#bd-example-modal"
                      data-toggle="modal"
                      className="nav-link"
                    >
                      <i className="mdi mdi-account-circle"></i>
                    </NavLink>
                  )}
                </li>
                <li className="cart-btn">
                  <CartSideBar />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <Login />
    </div>
  );
};

export default Navigation;
