import {
  GET_HOME_SLIDERS,
  GET_HOME_CATEGORIES,
  GET_HOME_BRANDS,
  GET_HOME_TOP_PRODUCTS,
  GET_HOME_SETTINGS,
  GET_HOME_BLOGS,
  GET_BLOG_DETAIL,
  GET_HOME_SERVICES,
  GET_HOME_OUTLETS,
  GET_HOME_BANNERS,
  GET_HOME_ABOUT_US,
  GET_PAGE_DETAIL,
  GET_PAGES
} from "../constants/homeConstants";

export function getHomeSliders(payload) {
  return { type: GET_HOME_SLIDERS, payload: payload };
}

export function getHomeCategories(payload) {
  return { type: GET_HOME_CATEGORIES, payload: payload };
}

export function getNavPages(payload) {
   return { type: GET_PAGES, payload: payload };
}
export function getHomeServices(payload) {
  return { type: GET_HOME_SERVICES, payload: payload };
}

export function getHomeBanners(payload) {
  return { type: GET_HOME_BANNERS, payload: payload };
}

export function getHomeBlogs(payload) {
  return { type: GET_HOME_BLOGS, payload: payload };
}

export function getHomeOutlets(payload) {
  return { type: GET_HOME_OUTLETS, payload: payload };
}

export function getHomeBrands(payload) {
  return { type: GET_HOME_BRANDS, payload: payload };
}

export function getHomeTopProducts(payload) {
  return { type: GET_HOME_TOP_PRODUCTS, payload: payload };
}

export function getHomeSettings(payload) {
  return { type: GET_HOME_SETTINGS, payload: payload };
}

export function getBlogDetail(payload) {
  return { type: GET_BLOG_DETAIL, payload: payload };
}

export function getHomeAboutUs(payload) {
  return { type: GET_HOME_ABOUT_US, payload: payload };
}

export function getPageDetail(payload) {
    return { type: GET_PAGE_DETAIL, payload: payload };
}

