import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ToastObjects } from "../../utils/toast/toastObject";
import { UPLOAD_URL } from "../../config";
import Pagination from "../../components/pagination";
import { Typography } from "@mui/material";
import mediaService from "../../services/mediaService";

function Media() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [mediassPerPage] = useState(6);
  const medias = useSelector((state) => state.media.medias);

  useEffect(() => {
     const getMedias = async () => {
      try {
        await mediaService.getMedias(dispatch);
      } catch (e) {
        toast.error(e.message, ToastObjects);
      }
    };
    getMedias();
  }, [dispatch]);

  const indexOfLastOutlet = currentPage * mediassPerPage;
  const indexOfFirstOutlet = indexOfLastOutlet - mediassPerPage;
  const currentMedias = medias.slice(indexOfFirstOutlet, indexOfLastOutlet);
  const onPageChange = (pageNumber) => setCurrentPage(pageNumber);

  const renderBreadcrumb = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Link to="/" className="breadcrumb-container">
              <strong>
                <span className="mdi mdi-home"/> Home
              </strong>
            </Link>{" "}
            <span className="custom-chevron"/>{" "}
            <Link to="#" className="breadcrumb-container">Medias</Link>{" "}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <section className="blog-detail-section">
        <div className="section-header-blog">
          <h5 className="p-2" >
            {renderBreadcrumb()}
          </h5>
        </div>
        <div className="container">
          <div className="row">
            {currentMedias.map((media, index) => (
                <div className="col-md-4" key={index}>
                  {media.link ? (
                    <iframe
                      width="100%"
                      height="auto"
                      src={media.link}
                      title={media.name}
                      allowFullScreen
                    />
                  ) : media.file ? (
                    <video
                      controls
                      src={UPLOAD_URL + media.file}
                      style={{ width: '100%', height: 'auto' }}
                    />
                  ) : (
                    <div>No video available</div>
                  )}
                  <div style={{ padding: '5px' }}>
                    <Typography variant="h5" className="text-center" gutterBottom>
                      {media.name}
                    </Typography>
                  </div>
                </div>
              ))}
          </div>
          <Pagination currentPage={currentPage} totalPages={Math.ceil(currentMedias.length / mediassPerPage)} onPageChange={onPageChange} />
        </div>
      </section>
    </div>
  );
}

export default Media;
