import { Link } from "react-router-dom";

export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

export function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}


export function formatHtmlContent(content) {
    return content && content.length > 0 ?  content.replace(/<[^>]*>/g, "") : content;
}

export function getDateTime(value) {
  if (!value) return "";
  return new Date(value).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function formatDateTime(value) {
if (!value) return "";
  return new Date(value).toISOString().split('T')[0];
}

export const toTitleCase = (str) => {
  if (str)
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  return str;
};

export const priceFormat = (currency, amount) => {
  let price = currency + ' ' + amount.toLocaleString("en-US");
  return price;
};

export const generateBreadcrumb = ({ links }) => {
   return (
    <div className="container breadcrumb-container">
      <div className="row">
        <div className="col-md-12">
          {links.map((link, index) => (
            <>
              {index > 0 && <span className="mdi mdi-chevron-right" />}
              <Link to={link.to}>
                <strong>{link.text}</strong>
              </Link>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
