import React, { useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import homeServices from "../../services/homeServices";
import { toast } from "react-toastify";
import { ToastObjects } from "../../utils/toast/toastObject";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UPLOAD_URL } from "../../config";
import banquetService from "../../services/banquetService";
import { Link } from "react-router-dom";
import { hideLoader, showLoader } from "../../store/actions/globalActions";
import LoaderContainer from "../../containers/LoaderContainer";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import fbImage from '../../fb.png';
import instaImage from '../../insta.png';
import linkedinImage from '../../linkedin.png';
import twitterImage from '../../twitter.png';


function Banquet() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const dispatch = useDispatch();
  const sliders = useSelector((state) => state.banquet.sliders);
  const siteSettings = useSelector((state) => state.home.settings);

  const BanquetSchema = Yup.object().shape({
    full_name: Yup.string()
      .matches(/^[a-zA-Z\s]*$/, "Only letters and spaces are allowed")
      .required("Full Name is required"),
    function_date: Yup.date().required("Function Date is required"),
    time: Yup.string().required("Party Time is required"),
    guaranteed_pax: Yup.number()
      .integer("Guaranteed Pax must be an integer")
      .required("Guaranteed Pax is required"),
    contact: Yup.string()
      .matches(/^[0-9]+$/, "Contact Number must contain only numbers")
      .required("Contact Number is required"),
    function_type: Yup.string().required("Function Type is required"),
  });

  useEffect(() => {
    const getSettings = async () => {
      try {
        await homeServices.getHomeSettings(dispatch);
      } catch (error) {
        toast.error(error.message, ToastObjects);
      }
    };
    getSettings();
  }, [dispatch]);

  useEffect(() => {
    const getSliders = async () => {
      try {
        dispatch(showLoader());
        await banquetService.getSliders(dispatch);
        dispatch(hideLoader());
      } catch (e) {
        toast.error(e.message, ToastObjects);
      }
    };
    getSliders();
  }, [dispatch]);

  return (
    <div>
      <LoaderContainer>
        <div className="section-header-blog">
          <h5 className="p-2">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <Link to="/" className="breadcrumb-container">
                    <strong>
                      <span className="mdi mdi-home" /> Home
                    </strong>
                  </Link>{" "}
                  <span className="custom-chevron" />{" "}
                  <Link to="#" className="breadcrumb-container">
                    Banquet
                  </Link>{" "}
                </div>
              </div>
            </div>
          </h5>
        </div>

        <section className="mt-5 pb-5 franchise-section">
          <div className="container">
            <div className="franchise-top-section">
              <Grid container spacing={6}>
                <Grid item xs={12} md={6} className="mt-5">
                  <Slider {...settings}>
                    {sliders &&
                      sliders.map((slider, index) => (
                        <div className="banner-slider-item" key={index}>
                          <a href={slider.link}>
                            <img
                              src={UPLOAD_URL + slider.image}
                              alt="slider"
                            />
                          </a>
                        </div>
                      ))}
                  </Slider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    style={{
                      backgroundColor: "#FFF",
                      padding: "30px",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="form-holder">
                      <Formik
                        initialValues={{
                          full_name: "",
                          function_date: "",
                          time: "",
                          guaranteed_pax: "",
                          contact: "",
                          function_type: "",
                        }}
                        validationSchema={BanquetSchema}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          try {
                            await banquetService.sendBanquetInquiry(values);
                            resetForm();
                            toast.success(
                              "Inquiry submitted successfully!",
                              ToastObjects
                            );
                          } catch (error) {
                            toast.error(
                              error.message,
                              ToastObjects
                            );
                          }
                          setSubmitting(false);
                        }}
                      >
                        {({ isSubmitting }) => (
                          <Form className="row">
                            <div className="col-md-12 col-lg-12 top_form">
                              <div className="form-header">
                                <p
                                  className="text-primary"
                                  style={{ fontSize: "24px" }}
                                >
                                  Banquet Enquiry
                                </p>
                                <span className="text-danger">
                                  {" "}
                                  <b>PLEASE FILL THE INFORMATION BELOW</b>
                                </span>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-6">
                                  <Field
                                    type="text"
                                    name="full_name"
                                    className="form-control mb-3"
                                    placeholder="Full Name"
                                  />
                                  <ErrorMessage
                                    name="full_name"
                                    component="div"
                                    className="text-danger"
                                  />
                                  <Field
                                    type="date"
                                    name="function_date"
                                    className="form-control mb-3"
                                    placeholder="Function Date"
                                  />
                                  <ErrorMessage
                                    name="function_date"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <Field
                                    type="time"
                                    name="time"
                                    className="form-control mb-3"
                                    placeholder="Party Time"
                                  />
                                  <ErrorMessage
                                    name="time"
                                    component="div"
                                    className="text-danger"
                                  />
                                  <Field
                                    type="text"
                                    name="guaranteed_pax"
                                    className="form-control mb-3"
                                    placeholder="Guaranteed Pax"
                                  />
                                  <ErrorMessage
                                    name="guaranteed_pax"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <Field
                                    type="text"
                                    name="contact"
                                    className="form-control mb-3"
                                    placeholder="Contact Number"
                                  />
                                  <ErrorMessage
                                    name="contact"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <Field
                                    type="text"
                                    name="function_type"
                                    className="form-control mb-3"
                                    placeholder="Function Type"
                                  />
                                  <ErrorMessage
                                    name="function_type"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 mt-5 text-right"><button type="submit"  disabled={isSubmitting} className="btn btn-secondary"><span className="text-secondary">Submit</span></button></div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Paper>
                  <h5 className="banquet-information">
                    WE WILL CONTACT YOU SHORTLY WITH A QUOTATION AT THE ABOVE
                    CONTACT INFORMATION SHARED
                  </h5>
                </Grid>
                <div className="row mt-5">
                 <div className="col-md-3 col-lg-3 social-media-container">
                   <a href={siteSettings.facebookUrl} target="_blank" className="text-decoration-none social-links" rel="noreferrer">
                      <img src={fbImage} alt="Facebook"/>
                    </a>
                    <a href={siteSettings.instagramUrl} target="_blank" className="text-decoration-none social-links" rel="noreferrer">
                      <img src={instaImage} alt="Instagram"/>
                    </a>
                    <a href={siteSettings.linkedinUrl} target="_blank" className="text-decoration-none social-links" rel="noreferrer">
                      <img src={linkedinImage} alt="Linkedin"/>
                    </a>
                    <a href={siteSettings.twitterUrl} target="_blank" className="text-decoration-none social-links" rel="noreferrer">
                      <img src={twitterImage} alt="Twitter"/>
                    </a>
                </div>

                  <div className="col-md-9 col-lg-9">
                    <p className="banquet-bottom-text">
                      <b>
                        <i>
                          Chwassa Jhol Momo provides you with the best banquet
                          experience with 5-star quality food and services for
                          your important events. Savor your important dates
                          with us!
                        </i>
                      </b>
                    </p>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </section>
      </LoaderContainer>
    </div>
  );
}

export default Banquet;
