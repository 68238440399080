import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import homeServices from "../../services/homeServices";
import { toast } from "react-toastify";
import { ToastObjects } from "../../utils/toast/toastObject";
import { UPLOAD_URL } from "../../config";
import Pagination from "../../components/pagination";

function Outlet() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [outletsPerPage] = useState(6);
  const outlets = useSelector((state) => state.home.outlets);
  const settings = useSelector((state) => state.home.settings);
  useEffect(() => {
    const getOutlets = async () => {
      try {
        await homeServices.getHomeOutlets(dispatch);
      } catch (e) {
        toast.error(e.message, ToastObjects);
      }
    };
    getOutlets();
  }, [dispatch]);

  const indexOfLastOutlet = currentPage * outletsPerPage;
  const indexOfFirstOutlet = indexOfLastOutlet - outletsPerPage;
  const currentOutlets = outlets.slice(indexOfFirstOutlet, indexOfLastOutlet);
  const onPageChange = (pageNumber) => setCurrentPage(pageNumber);

  const renderBreadcrumb = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Link to="/" className="breadcrumb-container">
              <strong>
                <span className="mdi mdi-home"/> Home
              </strong>
            </Link>{" "}
            <span className="custom-chevron"/>{" "}
            <Link to="#" className="breadcrumb-container">Outlets</Link>{" "}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <section className="blog-detail-section">
        <div className="section-header-blog">
          <h5 className="p-2" >
            {renderBreadcrumb()}
          </h5>
        </div>
        <div className="container">
          <div className="section-header">
            <div className="d-flex justify-content-center align-items-center mb-2 mt-2">
              <img src={UPLOAD_URL + settings.favicon } alt="Logo" className="logo" width={'250px'} height={'250px'} />
            </div>
          </div>
          <div className="row">
            {currentOutlets.map((outlet, index) => (
              <div className="col-md-4" key={index}>
                <div className="outlet-card">
                  <Link to={{ pathname: `/outlet/${outlet.slug}`, state: outlet }}>
                    <div className="outlet-card-header mb-2">
                      <img
                        className="img-fluid"
                        src={UPLOAD_URL + outlet.image}
                        alt="outlet-thumbnail"
                      />
                    </div>
                    <div className="outlet-card-body">
                      <span className="outlet-title">{outlet.outletName}</span>
                      <div className="outlet-description">
                        {outlet.address}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <Pagination currentPage={currentPage} totalPages={Math.ceil(outlets.length / outletsPerPage)} onPageChange={onPageChange} />
        </div>
      </section>
    </div>
  );
}

export default Outlet;
