import {
  getMedias,
} from "../store/actions/mediaActions";
import http from "./api";

const mediaService = {
  getMedias: async (dispatch) => {
    try {
      let res = await http.get(`/api/v1/medias`);
      dispatch(getMedias(res.data));
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  },
}
export default mediaService;
