import React, { useState } from "react";

function FranchiseForm() {
  const [step, setStep] = useState(1);
  const [foodBusiness, setFoodBusiness] = useState("");
  const [capitalInvestment, setCapitalInvestment] = useState("");
  const [preferredLocation, setPreferredLocation] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const handleFoodBusinessChange = (e) => {
    setFoodBusiness(e.target.value);
  };

  const handleCapitalInvestmentChange = (e) => {
    setCapitalInvestment(e.target.value);
  };

  const handlePreferredLocationChange = (e) => {
    setPreferredLocation(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
  };

  return (
    <section className="franchise-form-section mb-5" style={{ backgroundColor: "darkOrange" }}>
      <div className="container">
        <div className="row">
          <div className="franchise-form col-md-12 mb-5">
            <h2 className="text-primary">Let's Get started</h2>
            <form id="multi-step-form" onSubmit={handleSubmit}>
              {step === 1 && (
                <div className="form-step active" id="step-1">
                  <p className="text-white"> Do you own/run any <b>food business</b>?</p>
                  <div className="row">
                    <label className="col-md-2">
                      <input
                        name="foodBusiness"
                        type="radio"
                        value="Yes"
                        checked={foodBusiness === "Yes"}
                        onChange={handleFoodBusinessChange}
                        required
                      /> Yes
                    </label>
                    <label className="col-md-2">
                      <input
                        name="foodBusiness"
                        type="radio"
                        value="No"
                        checked={foodBusiness === "No"}
                        onChange={handleFoodBusinessChange}
                        required
                      /> No
                    </label>
                  </div>

                  <ul className="list-inline pull-left">
                    <li>
                      <button type="button" className="btn btn-sm next-step next-button" onClick={nextStep}>
                        <b className="text-white">NEXT</b>
                      </button>
                    </li>
                  </ul>
                </div>
              )}

              {step === 2 && (
                <div className="form-step" id="step-2">
                  <p className="text-white"> How much of <b>Capital Investment</b> are you offering?</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="capitalInvestment"
                          className="form-control col-md-12"
                          placeholder="Rs. "
                          value={capitalInvestment}
                          onChange={handleCapitalInvestmentChange}
                          required
                          style={{ backgroundColor: "transparent", color: "white" }}
                        />
                      </div>
                    </div>
                  </div>

                  <ul className="d-flex pull-left">
                    <li>
                      <button type="button" className="btn btn-sm btn-warning background-two previous-button" onClick={previousStep}>
                        <b className="text-white">PREVIOUS</b>
                      </button>
                    </li>
                    <li>
                      <button type="button" className="btn btn-sm next-step next-button" onClick={nextStep}>
                        <b className="text-white">NEXT</b>
                      </button>
                    </li>
                  </ul>
                </div>
              )}

              {step === 3 && (
                <div className="form-step" id="step-3">
                  <p className="text-white"> What is your <b>preferred location?</b></p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="preferredLocation"
                          className="form-control col-md-12"
                          placeholder="Area"
                          value={preferredLocation}
                          onChange={handlePreferredLocationChange}
                          required
                          style={{ backgroundColor: "transparent", color: "white" }}
                        />
                      </div>
                    </div>
                  </div>

                <ul className="d-flex pull-left">
                    <li>
                      <button type="button" className="btn btn-sm btn-warning background-two previous-button" onClick={previousStep}>
                        <b className="text-white">PREVIOUS</b>
                      </button>
                    </li>
                    <li>
                      <button type="button" className="btn btn-sm next-step next-button" onClick={nextStep}>
                        <b className="text-white">NEXT</b>
                      </button>
                    </li>
                  </ul>
                </div>
              )}

              {step === 4 && (
                <div className="form-step" id="step-4">
                  <p className="text-white"> Personal Details</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control col-md-12"
                          placeholder="Name"
                          value={name}
                          onChange={handleNameChange}
                          required
                          style={{ backgroundColor: "transparent", color: "white", marginBottom: "20px" }}
                        />
                        <input
                          type="text"
                          name="phone"
                          className="form-control col-md-12"
                          placeholder="Phone"
                          value={phone}
                          onChange={handlePhoneChange}
                          required
                          style={{ backgroundColor: "transparent", color: "white", marginBottom: "20px" }}
                        />
                        <input
                          type="email"
                          name="email"
                          className="form-control col-md-12"
                          placeholder="Email"
                          value={email}
                          onChange={handleEmailChange}
                          required
                          style={{ backgroundColor: "transparent", color: "white" }}
                        />
                      </div>
                    </div>
                  </div>

                 
                 <ul className="d-flex pull-left">
                    <li>
                      <button type="button" className="btn btn-sm btn-warning background-two previous-button" onClick={previousStep}>
                        <b className="text-white">PREVIOUS</b>
                      </button>
                    </li>
                    <li>
                      <button type="submit" name="submit_business_request" className="btn btn-secondary btn-submit">
                        <b className="text-white">SUBMIT</b>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FranchiseForm;
