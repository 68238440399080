import React, { useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ToastObjects } from "../../utils/toast/toastObject";
import { UPLOAD_URL } from "../../config";
import DOMPurify from "dompurify";
import { Paper, Typography, Button, Grid } from "@mui/material";
import { formatDateTime } from "../../utils/helper";
import blogService from "../../services/blogService";
import { hideLoader, showLoader } from "../../store/actions/globalActions";
import LoaderContainer from "../../containers/LoaderContainer";
import homeServices from "../../services/homeServices";
import Slider from "react-slick";

function NewsDetail() {

  const { slug } = useParams();
  const dispatch = useDispatch();
  const { blog } = useSelector((state) => state.blog);
  let blogs = useSelector((state) => state.home.blogs);
  const navigate = useNavigate();
  const filteredNews = blogs.filter((item) => item.slug !== slug && item.type === 'NEWS');
  const shuffledNews = filteredNews.sort(() => Math.random() - 0.5);
  const randomNews = shuffledNews.slice(0, 3);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: blogs.length >= 3 ? 3 : blogs.length,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: blogs.length >= 3 ? 3 : blogs.length,
          slidesToScroll: blogs.length >= 3 ? 3 : blogs.length,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: blogs.length >= 2 ? 2 : blogs.length,
          slidesToScroll: blogs.length >= 2 ? 2 : blogs.length,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
         slidesToShow: blogs.length >= 2 ? 2 : blogs.length,
          slidesToScroll: blogs.length >= 2 ? 2 : blogs.length,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  useEffect(() => {
    const getBlogs = async () => {
      try {
        await homeServices.getHomeBlogs(dispatch);
      } catch (e) {
        toast.error(e.message, ToastObjects);
      }
    };
    getBlogs();
  }, [dispatch]);
  
  useEffect(() => {
    const getBlogDetail = async () => {
      try {
        dispatch(showLoader());
        await blogService.getBlogDetail(slug, dispatch);
        dispatch(hideLoader());
      } catch (e) {
        toast.error(e.message, ToastObjects);
      }
    };
    getBlogDetail();
  }, [dispatch, slug]);
 

  const renderBreadCrumb = () => {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Link to="/" className="breadcrumb-container">
                <strong>
                  <span className="mdi mdi-home"/> Home
                </strong>
              </Link>{" "}
              <span className="custom-chevron"/>{" "}
              <Link to="/blogs" className="breadcrumb-container">Blogs</Link>{" "}
              <span className="custom-chevron"/>{" "}
              <Link to="#" className="breadcrumb-container">{blog?.slug?.toUpperCase()}</Link>{" "}
            </div>
          </div>
        </div>
      );
    }
    

  return (
    <div>
      <LoaderContainer>
      <section className="blog-detail-section">
        <div className="section-header-blog">
            <h5 className="p-2" >
              {renderBreadCrumb()}
            </h5>
          </div>
        <div className="container mb-5">
          {blog && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Paper elevation={3}>
                  <img
                    src={UPLOAD_URL + blog.image}
                    alt="blog-thumbnail"
                    style={{ width: "100%"}}
                  />
                  <div style={{ padding: "20px" }}>
                    <Typography variant="h5" gutterBottom>
                      {blog.title}
                    </Typography>
                    <Typography variant="subtitle" color="blue" className="font-weight-bolder " paragraph>
                      By {blog.author_name} | {formatDateTime(blog.createdAt)}
                    </Typography>
                    <Typography  className="mt-5 blog-detail-description"  variant="body2" color="black" paragraph dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog.description) }} />
                  </div>
                 <Button variant="contained" onClick={() => navigate("/blogs")} color="primary" style={{ margin: "20px" }}>                 
                 Back to Blog List
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          )}
        </div>
{randomNews && randomNews.length > 0 ? <div className="blog-items-slider mb-5 container" id="header-category-bk">
            <div className="section-header">
              <h5 className="heading-design-h5 text-primary">
              See More
                <Link to="/news">
                  <span className="float-right text-secondary">View All</span>
                </Link>
              </h5>
            </div>
            <Slider {...settings}>
              {randomNews.length > 0 &&
                randomNews.map((news, index) => (
                  <div className="item" key={index}>
                    <div className="blog-card">
                      <Link to={{ pathname:`/news/${news.slug}`, state: blog }}>
                        <div className="blog-card-header">
                          <img
                            className="img-fluid"
                            src={UPLOAD_URL + news.image}
                            alt="blog-thumbnail"
                          />
                        </div>
                        <div className="blog-card-body">
                          <h5 className="blog-title">{news.name}</h5> 
                          <div className="blog-metadata">
                          </div>
                          <div className="blog-description">
                              {`${news.summary?.substring(0, 400)}...`}
                            </div>
                        </div>
                      </Link>
                      <div className="blog-card-footer">
                        <Link
                          to={{ pathname:`/news/${news.slug}`, state: blog }}
                          className="btn read-more-btn btn-sm float-right"
                        >
                          <i className="mdi mdi-arrow-right" /> Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
        </div> : ''}
      </section>
      </LoaderContainer>
    </div>
  );
}

export default NewsDetail;
