import {
  getBlogDetail,
  getHomeBlogs,
  getHomeBrands,
  getHomeCategories,
  getHomeSettings,
  getHomeSliders,
  getHomeTopProducts,
  getHomeServices,
  getHomeOutlets,
  getHomeBanners,
  getHomeAboutUs,
  getPageDetail,
  getNavPages
} from "../store/actions/homeActions";
import http from "./api";
import store from "../store";

const homeServices = {
  getSliders: async () => {
    try {
      let res = await http.get("/api/v1/home/sliders");
      store.dispatch(getHomeSliders(res.data));
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getPages: async () => {
   try {
      let res = await http.get("/api/v1/home/pages");
      store.dispatch(getNavPages(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPageDetail: async (slug) => {
   try {
      let res = await http.get("/api/v1/home/page/" +  slug);
      store.dispatch(getPageDetail(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  sendFeedbackInquiry: async (payload) => {
   try {
      let res = await http.post("/api/v1/home/feedback", payload);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getHomeAboutUs: async () => {
    try {
      let res = await http.get("/api/v1/home/page/about-the-company");
      store.dispatch(getHomeAboutUs(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getHomeCategories: async () => {
    try {
      let res = await http.get("/api/v1/home/categories");
      store.dispatch(getHomeCategories(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getHomeBrands: async () => {
    try {
      let res = await http.get("/api/v1/home/brands");
      store.dispatch(getHomeBrands(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getHomeOutlets: async () => {
    try {
      let res = await http.get("/api/v1/home/outlets");
      store.dispatch(getHomeOutlets(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } 
  },

  getHomeTopProducts: async () => {
    try {
      let res = await http.get("/api/v1/home/top-products");
      store.dispatch(getHomeTopProducts(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getHomeBlogs: async () => {
    try {
      let res = await http.get("/api/v1/home/blogs");
      store.dispatch(getHomeBlogs(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getHomeBanners: async (dispatch) => {
    try {
      let res = await http.get("/api/v1/home/banners");
      dispatch(getHomeBanners(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getHomeServices: async (dispatch) => {
    try {
      let res = await http.get("/api/v1/home/services");
      dispatch(getHomeServices(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  
  getBlogDetail: async (slug, dispatch) => {
    try {
      let res = await http.get("/api/v1/home/blog/" + slug);
      dispatch(getBlogDetail(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getHomeSettings: async (dispatch) => {
    try {
      let res = await http.get("/api/v1/home/general-settings");
      dispatch(getHomeSettings(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default homeServices;
