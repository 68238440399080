import {
  GET_SLIDERS,
  SEND_BANQUET_INQUIRY
} from "../constants/banquetConstants";

export function getBanquetSliders(payload) {
  return { type: GET_SLIDERS, payload: payload };
}

export function sendBanquetInquiry(payload) {
   return { type: SEND_BANQUET_INQUIRY, payload: payload };
}