import React from "react";
import { Link } from "react-router-dom";

function Pagination({ currentPage, totalPages, onPageChange }) {
  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center mb-5">
        {/* Previous button */}
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <Link
            className="page-link"
            to="#"
            onClick={() => onPageChange(currentPage - 1)}
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
          </Link>
        </li>
        {/* Page numbers */}
        {Array.from({ length: totalPages }).map((_, index) => (
          <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
            <Link
              onClick={() => onPageChange(index + 1)}
              className={`page-link ${currentPage === index + 1 ? 'text-orange' : ''}`}
              to="#"
            >
              {index + 1}
            </Link>
          </li>
        ))}
        {/* Next button */}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <Link
            className="page-link"
            to="#"
            onClick={() => onPageChange(currentPage + 1)}
            aria-label="Next"
          >
            <span aria-hidden="true">&raquo;</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
