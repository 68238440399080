import authServices from "../services/authService";
import store from "../store";

export const authLoader = async () => {
  const userProfile = store.getState().user.profile;
  if (!userProfile|| Object.keys(userProfile).length === 0) {
    const token = localStorage.getItem("accessToken");
    if (token) {
      await authServices.userProfile();
      return store.getState().user.profile;
    }
  }
  return userProfile
};
