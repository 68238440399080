import http from "./api";
import { getUserProfile, loginUser } from "../store/actions/authActions";
import store from "../store";

const dispatch = store.dispatch;

const authServices = {
  loginUser: async (data) => {
    try {
      let res = await http.post("/api/v1/auth/login", data);
      if (res.accessToken) {
        localStorage.setItem("accessToken", res.accessToken);
      }
      dispatch(loginUser(res.user));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  registerUser: async (data) => {
    try {
      let res = await http.post("/api/v1/auth/register", data);
      if (res.accessToken) {
        localStorage.setItem("accessToken", res.accessToken);
      }
      dispatch(loginUser(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  userProfile: async () => {
    try {
      let res = await http.get("/api/v1/auth/profile");
      dispatch(getUserProfile(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authServices;
