export const GET_HOME_SLIDERS = "GET_HOME_SLIDERS";
export const GET_HOME_CATEGORIES = "GET_HOME_CATEGORIES";
export const GET_HOME_BRANDS = "GET_HOME_BRANDS";
export const GET_HOME_TOP_PRODUCTS = "GET_HOME_TOP_PRODUCTS";
export const GET_HOME_SETTINGS = "GET_HOME_SETTINGS";
export const GET_HOME_BLOGS = "GET_HOME_BLOGS";
export const GET_BLOG_DETAIL = "GET_BLOG_DETAIL";
export const GET_HOME_SERVICES = "GET_HOME_SERVICES";
export const GET_HOME_OUTLETS = "GET_HOME_OUTLETS";
export const GET_HOME_BANNERS = "GET_HOME_BANNERS";
export const GET_HOME_ABOUT_US = "GET_HOME_ABOUT_US";
export const GET_PAGE_DETAIL = "GET_PAGE_DETAIL";
export const GET_PAGES = "GET_PAGES";
