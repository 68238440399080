import { combineReducers } from "redux";
import userAuthReducer from "./authReducers";
import homeReducers from "./homeReducers";
import cartReducers from "./cartReducers";
import blogReducers from "./blogReducers";
import franchiseReducers from "./franchiseReducers"
import banquetReducers from "./banquetReducers";
import globalReducers from "./globalReducers";
import mediaReducers from "./mediaReducers";

const appReducer = combineReducers({
  user: userAuthReducer,
  home: homeReducers,
  cart: cartReducers,
  blog: blogReducers,
  franchise: franchiseReducers,
  banquet: banquetReducers,
  global: globalReducers,
  media:mediaReducers,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
