import { GET_TESTONOMIALS, GET_SERVICES, GET_BANNERS, GET_MEDIA
} from "../constants/franchiseConstants";

export function getFranchiseTestonomials(payload) {
  return { type: GET_TESTONOMIALS, payload: payload };
}

export function getFranchiseServices(payload) {
  return { type: GET_SERVICES, payload: payload };
}

export function getFranchiseBanners(payload) {
   return { type: GET_BANNERS, payload: payload };
}

export function getFranchiseMedia(payload) {
    return { type: GET_MEDIA, payload: payload };
}
