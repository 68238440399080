import React, { useEffect } from "react";
import { Paper, Typography, Grid } from "@mui/material";
import { Link, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ToastObjects } from "../../utils/toast/toastObject";
import { UPLOAD_URL } from "../../config";
import { formatHtmlContent } from "../../utils/helper";
import homeServices from "../../services/homeServices";
import mediaService from "../../services/mediaService";

function Page() {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const page = useSelector((state) => state.home.page);
  const medias = useSelector((state) => state.media.medias);
  const location = useLocation();

  useEffect(() => {
    const getAboutUs = async () => {
      try {
        await homeServices.getPageDetail(slug, dispatch);
      } catch (e) {
        toast.error(e.message, ToastObjects);
      }
    };
    const getMedias = async () => {
      try {
        await mediaService.getMedias(dispatch);
      } catch (e) {
        toast.error(e.message, ToastObjects);
      }
    };
    getAboutUs();
    if (location.pathname === "/about-us") {
      getMedias();
    }
  }, [dispatch, slug, location]);

  const renderBreadCrumb = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Link to="/" className="breadcrumb-container">
              <strong>
                <span className="mdi mdi-home" /> Home
              </strong>
            </Link>{" "}
            <span className="custom-chevron" />{" "}
            <Link to="#" className="breadcrumb-container">
              {page.slug?.toUpperCase()}
            </Link>{" "}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <section className="blog-detail-section">
        <div className="section-header-blog">
          <h5 className="p-2">{renderBreadCrumb()}</h5>
        </div>
        <div className="container mb-5">
          <div className="row">
            <Paper className="container" elevation={3}>
              <div className="">
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <img
                      src={UPLOAD_URL + page.image}
                      alt="thumbnail"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                    <div style={{ padding: "20px" }}>
                      <Typography variant="h5" gutterBottom>
                        {page.title}
                      </Typography>
                      <Typography variant="body1" color="black">
                        {formatHtmlContent(page.description)}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>
          {location.pathname === "/about-us" && (
            <div className="mt-5">
              <div className="section-header">
                <h5 className="heading-design-h5 text-primary">
                  Media
                  <Link to="/medias">
                    <span className="float-right text-secondary">
                      View All
                    </span>
                  </Link>
                </h5>
              </div>
              <div className="card">
                <div className="card-body">
                    {medias.map((media, index) => (
                    <div className="col-12 col-sm-6 col-md-4" key={index}>
                      <div style={{ position: "relative", height: "100%" }}>
                        {media.link ? (
                          <iframe
                            width="100%"
                            height="100%"
                            src={media.link}
                            title={media.name}
                            allowFullScreen
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%"
                            }}
                          />
                        ) : media.file ? (
                          <video
                            controls
                            src={UPLOAD_URL + media.file}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          <div>No video available</div>
                        )}
                      </div>
                     <div className="card-footer">
                        <Typography
                          variant="h6"
                          className="text-center"
                          gutterBottom
                        >
                          {media.name}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default Page;
