import React, { useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { UPLOAD_URL } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import homeServices from "../../../services/homeServices";
import { toast } from "react-toastify";
import { ToastObjects } from "../../../utils/toast/toastObject";

function Outlet() {
  const dispatch = useDispatch();
  let outlets = useSelector((state) => state.home.outlets);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: outlets.length >= 3 ? 3 : outlets.length,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: outlets.length >= 3 ? 3 : outlets.length,
          slidesToScroll: outlets.length >= 3 ? 3 : outlets.length,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: outlets.length >= 2 ? 2 : outlets.length,
          slidesToScroll: outlets.length >= 2 ? 2 : outlets.length,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: outlets.length >= 2 ? 2 : outlets.length,
          slidesToScroll: outlets.length >= 2 ? 2 : outlets.length,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  useEffect(() => {
    const getOutlets = async () => {
      try {
        await homeServices.getHomeOutlets(dispatch);
      } catch (e) {
        toast.error(e.message, ToastObjects);
      }
    };
    getOutlets();
  }, [dispatch]);


  return (
    <section className="container outlet-items-slider mb-5">
        <div className="section-header">
          <h5 className="heading-design-h5 text-primary">
          Our Outlets
            <Link to={{ pathname:`/outlets`}}>
              <span className="float-right text-secondary">View All</span>
            </Link>
          </h5>
        </div>
        <Slider {...settings}>
          {outlets &&
            outlets.length > 0 &&
            outlets.map((outlet, index) => (
              <div className="item" key={index}>
               <div className="outlet-card m-2">
                   <Link to={{ pathname: `/outlet/${outlet.slug}`, state: outlet }}>
                      <div className="outlet-card-header mb-2">
                        <img
                          className="img-fluid zoom"
                          src={UPLOAD_URL + outlet.image}
                          alt="outlet-thumbnail"
                        />
                      </div>
                      <div className="outlet-card-body">
                        <span className="outlet-title">{outlet.outletName}</span>
                        <div className="outlet-description">
                          {outlet.address}
                        </div>
                      </div>
                    </Link>
                  </div>
              </div>
            ))}
        </Slider>
    </section>
);
}

export default Outlet;
