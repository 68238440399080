import {
  GET_MEDIAS,
} from "../constants/mediaConstants";

const initalState = {
  medias: [],
};
export default function mediaReducers(state = initalState, action) {
  switch (action.type) {
    case GET_MEDIAS: {
      return {
        ...state,
        medias: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
