import React, { Suspense } from "react";
  import {  RouterProvider, createBrowserRouter } from "react-router-dom";
  import { routes } from "./routes";
  import Toast from "./utils/toast/toast";
  import "react-toastify/dist/ReactToastify.css";

  const router = createBrowserRouter(routes);

  function App() {
   return (
      <>
        <Toast />
        <Suspense>
          <RouterProvider router={router}/>
        </Suspense>
      </>
    );
  }

  export default App;
