import React from "react";
import { toast } from "react-toastify";
import { ToastObjects } from "../../utils/toast/toastObject";
import authServices from "../../services/authService";
import { useDispatch } from "react-redux";
import Register from "./register";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Email address is required"),
    password: Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const { email, password } = values;
        await validationSchema.validate({ email, password }, { abortEarly: false });
        let res = await authServices.loginUser({ ...values, panel: "user" }, dispatch);
        if (res && res.status) {
          navigate('/dashboard');
          toast.success("User successfully logged in", ToastObjects);
        }
      } catch (error) {
        if (error.name === 'ValidationError') {
          error.inner.forEach(err => {
            formik.setFieldError(err.path, err.message);
          });
        } else {
          toast.error(error.message || "Something went wrong", ToastObjects);
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div>
      <div className="modal fade login-modal-main" id="bd-example-modal">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="login-modal">
                <div className="row">
                  <div className="col-lg-12 pad-left-0">
                    <button
                      type="button"
                      className="close close-top-right"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i className="mdi mdi-close" />
                      </span>
                      <span className="sr-only">Close</span>
                    </button>
                    <div className="login-modal-right">
                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          id="login"
                          role="tabpanel"
                        >
                          <h5 className="heading-design-h5">
                            Login to your account
                          </h5>
                          <form noValidate onSubmit={formik.handleSubmit}>
                            <fieldset className="form-group">
                              <label>Enter Email Address</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="Enter Email Address"
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger">{formik.errors.email}</div>
                              ) : null}
                            </fieldset>
                            <fieldset className="form-group">
                              <label>Enter Password</label>
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="******"
                              />
                              {formik.touched.password && formik.errors.password ? (
                                <div className="text-danger">{formik.errors.password}</div>
                              ) : null}
                            </fieldset>

                            <fieldset className="form-group">
                              <button
                                type="submit"
                                className="btn btn-lg btn-warning btn-block"
                                disabled={formik.isSubmitting}
                              >
                                {formik.isSubmitting ? "Logging in..." : "Login to your account"}
                              </button>
                            </fieldset>
                          </form>
                        </div>
                        <div className="tab-pane" id="register" role="tabpanel">
                          <Register />
                        </div>
                      </div>
                      <div className="clearfix" />
                      <div className="text-center login-footer-tab">
                        <ul className="nav nav-tabs" role="tablist">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#login"
                              role="tab"
                            >
                              <i className="mdi mdi-lock" /> LOGIN
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#register"
                              role="tab"
                            >
                              <i className="mdi mdi-pencil" /> REGISTER
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="clearfix" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
